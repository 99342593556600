<template>
  <component :is="testData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="testData === undefined"
    >
      <h4 class="alert-heading">
        Đã xảy ra lỗi khi lấy dữ liệu
      </h4>
      <div class="alert-body">
        Không tìm thấy thông tin người dùng. Quay lại
        <b-link
          class="alert-link"
          :to="{ name: 'writing-list'}"
        >
          Writing Tests
        </b-link>
      </div>
    </b-alert>
    <b-card
      v-if="testData"
      id="add-new-user-sidebar"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h2 class="mb-0">
            Chỉnh sửa bài test writing
          </h2>

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Tiêu đề"
              rules="required"
            >
              <b-form-group
                label="Tiêu đề"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="testData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Writing Test tháng 01/2022"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Plan -->
            <validation-provider
              #default="validationContext"
              name="Loại"
              rules="required"
            >
              <b-form-group
                label="Loại"
                label-for="plan"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="testData.plan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="planOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="plan"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div
              v-for="(section, id) in testData.writing_sections"
              :key="id"
            >
              <b-card
                :title="section.title"
                style="border-style: solid;"
              >
                <b-form-group>
                  <quill-editor
                    id="blog-content"
                    v-model="section.writing_question_groups[0].writing_single_questions[0].content"
                    :options="snowOption"
                    placeholder="Nhập nội dung tại đây"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                  />
                </b-form-group>
              </b-card>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Lưu thông tin
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="resetForm"
              >
                Xóa dữ liệu
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-card>
  </component>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { quillEditor } from 'vue-quill-editor'
import writingStoreModule from '@/views/tests/writing/writingStoreModule'
import router from '@/router'
import { planOptions } from '@core/utils/plan'
import ImageResize from 'quill-image-resize-module'

export default {
  name: 'WritingEdit',
  directives: {
    Ripple,
  },
  components: {
    BAlert,
    BLink,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      window.Quill.register('modules/imageResize', ImageResize)
    },
  },
  setup(props, { emit }) {
    const TEST_WRITING_STORE_MODULE_NAME = 'test-writing'

    if (!store.hasModule(TEST_WRITING_STORE_MODULE_NAME)) {
      store.registerModule(TEST_WRITING_STORE_MODULE_NAME, writingStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(TEST_WRITING_STORE_MODULE_NAME)) {
        store.unregisterModule(TEST_WRITING_STORE_MODULE_NAME)
      }
    })
    const testData = ref(null)
    const unsavedTestData = ref(null)
    store.dispatch(`${TEST_WRITING_STORE_MODULE_NAME}/fetchWritingTest`, { id: router.currentRoute.params.id })
      .then(response => {
        testData.value = response.data
        unsavedTestData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          testData.value = undefined
        }
      })

    const resetTestData = () => {
      testData.value = JSON.parse(JSON.stringify(unsavedTestData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTestData)

    return {
      testData,
      unsavedTestData,

      refFormObserver,
      getValidationState,
      resetForm,
      planOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
@import '@core/scss/vue/libs/quill.scss';
</style>
